export const organizationsData = [
  {
    name: '技术部',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.88,
    averageHour: 7.5,
    topUsers: [
      { id: 1, name: '张三', rate: '0.8', hour: 6.3 },
      { id: 2, name: '李四', rate: '0.78', hour: 6.2 },
      { id: 3, name: '王小五', rate: '0.75', hour: 6.0 },
    ],
    userStatistics: {
      active: 5,
      inactive: 0,
      offline: 30,
    }
  },
  {
    name: '人力资源部',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.85,
    averageHour: 6.5,
    topUsers: [
      { id: 1, name: '张三', rate: '0.8', hour: 6.3 },
      { id: 2, name: '李四', rate: '0.78', hour: 6.2 },
      { id: 3, name: '王小五', rate: '0.75', hour: 6.0 },
    ],
    userStatistics: {
      active: 5,
      inactive: 0,
      offline: 30,
    }
  },
  {
    name: '财务部',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.83,
    averageHour: 5.5,
    topUsers: [
      { id: 1, name: '张三', rate: '0.8', hour: 6.3 },
      { id: 2, name: '李四', rate: '0.78', hour: 6.2 },
      { id: 3, name: '王小五', rate: '0.75', hour: 6.0 },
    ],
    userStatistics: {
      active: 5,
      inactive: 0,
      offline: 30,
    }
  },
  {
    name: '总经办',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.78,
    averageHour: 4.5,
    topUsers: [
      { id: 1, name: '张三', rate: '0.8', hour: 6.3 },
      { id: 2, name: '李四', rate: '0.78', hour: 6.2 },
      { id: 3, name: '王小五', rate: '0.75', hour: 6.0 },
    ],
    userStatistics: {
      active: 5,
      inactive: 0,
      offline: 30,
    }
  },
  {
    name: '法务部',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.78,
    averageHour: 4.5,
    topUsers: [
      { id: 1, name: '张三', rate: '0.8', hour: 6.3 },
      { id: 2, name: '李四', rate: '0.78', hour: 6.2 },
      { id: 3, name: '王小五', rate: '0.75', hour: 6.0 },
    ],
    userStatistics: {
      active: 5,
      inactive: 0,
      offline: 30,
    }
  },
  {
    name: '商务部',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.68,
    averageHour: 3.5,
    topUsers: [
      { id: 1, name: '张三', rate: '0.8', hour: 6.3 },
      { id: 2, name: '李四', rate: '0.78', hour: 6.2 },
      { id: 3, name: '王小五', rate: '0.75', hour: 6.0 },
    ],
    userStatistics: {
      active: 5,
      inactive: 0,
      offline: 30,
    }
  },
  {
    name: '设计部',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.66,
    averageHour: 2.5,
    topUsers: [
      { id: 1, name: '张三', rate: '0.8', hour: 6.3 },
      { id: 2, name: '李四', rate: '0.78', hour: 6.2 },
      { id: 3, name: '王小五', rate: '0.75', hour: 6.0 },
    ],
    userStatistics: {
      active: 5,
      inactive: 0,
      offline: 30,
    }
  },
  {
    name: '营销部',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.58,
    averageHour: 2.5,
    topUsers: [
      { id: 1, name: '张三', rate: '0.8', hour: 6.3 },
      { id: 2, name: '李四', rate: '0.78', hour: 6.2 },
      { id: 3, name: '王小五', rate: '0.75', hour: 6.0 },
    ],
    userStatistics: {
      active: 5,
      inactive: 0,
      offline: 30,
    }
  },
];

export const usersData = [
  {
    name: '张三',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.88,
    averageHour: 7.5,
    activeTime: {
      login: '08:25',
      logout: '23:02' 
    },
    website: {
      name: 'Salesforce.com',
      desc: 'Salesforce.com - Enterprise Edition'
    }
  },
  {
    name: '李四',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.85,
    averageHour: 6.5,
    activeTime: {
      login: '08:25',
      logout: '23:02' 
    },
    website: {
      name: 'Salesforce.com',
      desc: 'Salesforce.com - Enterprise Edition'
    }
  },
  {
    name: '王五',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.83,
    averageHour: 5.5,
    activeTime: {
      login: '08:25',
      logout: '23:02' 
    },
    website: {
      name: 'Salesforce.com',
      desc: 'Salesforce.com - Enterprise Edition'
    }
  },
  {
    name: '刘达',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.78,
    averageHour: 4.5,
    activeTime: {
      login: '08:25',
      logout: '23:02' 
    },
    website: {
      name: 'Salesforce.com',
      desc: 'Salesforce.com - Enterprise Edition'
    }
  },
  {
    name: '戚薇',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.78,
    averageHour: 4.5,
    activeTime: {
      login: '08:25',
      logout: '23:02' 
    },
    website: {
      name: 'Salesforce.com',
      desc: 'Salesforce.com - Enterprise Edition'
    }
  },
  {
    name: '李诞',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.68,
    averageHour: 3.5,
    activeTime: {
      login: '08:25',
      logout: '23:02' 
    },
    website: {
      name: 'Salesforce.com',
      desc: 'Salesforce.com - Enterprise Edition'
    }
  },
  {
    name: '张朝阳',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.66,
    averageHour: 2.5,
    activeTime: {
      login: '08:25',
      logout: '23:02' 
    },
    website: {
      name: 'Salesforce.com',
      desc: 'Salesforce.com - Enterprise Edition'
    }
  },
  {
    name: '陆小五',
    mostUsedSoftwareCategoryName: 'Office办公软件',
    mostUsedSoftwareName: 'Excel',
    averageRate: 0.58,
    averageHour: 2.5,
    activeTime: {
      login: '08:25',
      logout: '23:02' 
    },
    website: {
      name: 'Salesforce.com',
      desc: 'Salesforce.com - Enterprise Edition'
    }
  },
];
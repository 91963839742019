<template>
  <div class="card">
    <div class="card_header" @click="onHeaderClick">
      <i v-if="nameLegendColor" class="card_header-legend" :style="`background-color: ${nameLegendColor}`"></i>
      <h3>{{name}}</h3>
    </div>
    <ul v-if="userStatusStatistics.length" class="card_user-statistics">
      <li
        v-for="item in userStatusStatistics"
        :key="item.key"
        :class="item.id"
      >
        <i class="iconfont icon-user-filled"></i>
        {{item.value}}
      </li>
    </ul>
    <div v-if="website" class="card_website">
      <h4>{{website.name}}</h4>
      <p>{{website.desc}}</p>
    </div>
    <div v-if="activeTime" class="card_active-time">
      <h4>当日最早登录时间：{{activeTime.login}}</h4>
      <h4>当日最后下线时间：{{activeTime.logout}}</h4>
    </div>
    <div class="card_average">
      <div class="card_average_rate">
        <h4>{{(averageRate * 100).toFixed(1)}}%</h4>
        <p>{{averageRateDesc}}</p>
        <div class="card_average_rate-filled" :style="`width: ${averageRate * 100}%`"></div>
      </div>
      <p class="card_average-hour">
        <strong>{{averageHour}}小时</strong>
        {{averageHourDesc}}
      </p>
    </div>
    <ul v-if="topUsers && topUsers.length" class="card_top-user">
      <li class="title">Top Users</li>
      <li
        v-for="item in topUsers"
        :key="item.id"
      >
        <span class="card_top-user_name">{{item.name}}</span>
        <span class="card_top-user_rate">{{(item.rate * 100).toFixed()}}%</span>
        <span class="card_top-user_hour">{{item.hour}}小时</span>
      </li>
    </ul>
    <ul class="card_software">
      <li
        v-for="item in softwares"
        :key="item.id"
      >
        <h5>{{item.title}}</h5>
        <p>{{item.desc}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { isNil } from 'lodash';

export default {
  props: {
    onHeaderClick: Function,
    name: String,
    nameLegendColor: String,
    userStatistics: {
      active: 0,
      inactive: 0,
      offline: 0,
    },
    averageRate: Number,
    averageRateDesc: String,
    averageHour: Number,
    averageHourDesc: String,
    topUsers: Array,
    mostUsedSoftwareCategoryName: String,
    mostUsedSoftwareName: String,
    website: {
      name: '',
      desc: '',
    },
    activeTime: {
      login: '',
      logout: '',
    }
  },
  computed: {
    userStatusStatistics() {
      return isNil(this.userStatistics) ? [] : [
        {
          id: 'active',
          value: this.userStatistics.active,
        },
        {
          id: 'inactive',
          value: this.userStatistics.inactive,
        },
        {
          id: 'offline',
          value: this.userStatistics.offline,
        },
      ];
    },
    softwares() {
      return [
        { id: 'category', title: this.mostUsedSoftwareCategoryName, desc: '最常用大类' },
        { id: 'software', title: this.mostUsedSoftwareName, desc: '最常用软件' },
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-top: $padding-l;
  padding: $padding-s;
  border-radius: 12px;
  background-color: $color-white;
  &_header {
    display: flex;
    align-items: center;
    padding: $padding-xxs 0;
    border-bottom: 1px solid $color-border-secondary;
    cursor: pointer;
    h3 {
      font-size: 16px;
      color: $color-text-primary;
    }
    &-legend {
      margin-right: $padding-s;
      width: 12px;
      height: 12px;
      border-radius: 100%;
    }
  }
  &_user-statistics {
    display: flex;
    justify-content: center;
    padding: $padding-s 0;
    li {
      display: flex;
      align-items: center;
      color: $color-text-secondary;
      margin-left: $padding;
      font-size: 14px;
      &:first-child {
        margin-left: 0;
      }
      .iconfont {
        margin-right: 5px;
      }
      &.active {
        .iconfont {
          color: #2289BB;
        }
      }
      &.inactive {
        .iconfont {
          color: rgba(34, 137, 187, 0.5);
        }
      }
      &.offline {
        .iconfont {
          color: #D2D2D2;
        }
      }
    }
  }
  &_website {
    padding: $padding-s 0;
    h4 {
      font-size: 12px;
      color: $color-text-primary;
    }
    p {
      font-size: 12px;
      color: $color-text-secondary;
    }
  }
  &_active-time {
    padding: $padding-s 0;
    h4 {
      font-size: 12px;
      color: $color-text-primary;
    }
  }
  &_average {
    &_rate {
      position: relative;
      margin-left: -$padding-s;
      margin-right: -$padding-s;
      padding: $padding-xxs;
      background-color: $color-border-regular;
      color: $color-white;
      text-align: center;
      h4 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
      > * {
        position: relative;
        z-index: 1;
      }
      &-filled {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-secondary;
        z-index: 0;
      }
    }
    &-hour {
      padding: $padding-xxs;
      text-align: center;
      color: $color-text-primary;
      border-bottom: 1px solid $color-border-secondary;
      font-size: 14px;
    }
  }
  &_top-user {
    border-bottom: 1px solid $color-border-secondary;
    .title {
      padding: $padding-xxs;
      text-align: center;
      font-size: 14px;
      color: $color-text-regular;
    }
    li:not(.title) {
      padding: 0 $padding-s $padding-xxs;
      display: flex;
      align-items: center;
      font-size: 12px;
      .card_top-user_name {
        width: 60px;
      }
      .card_top-user_rate {
        flex: 1;
        text-align: center;
      }
      .card_top-user_hour {
        text-align: right;
        width: 60px;
      }
    }
  }
  &_software {
    li {
      padding: $padding-xxs $padding-s;
      border-bottom: 1px solid $color-border-secondary;
      &:last-child {
        border-bottom: 0;
      }
    }
    h5 {
      font-size: 14px;
      color: $color-text-primary;
    }
    p {
      font-size: 12px;
      color: $color-text-secondary;
    }
  }
}
</style>
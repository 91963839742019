<template>
  <div class="report-fliters">
    <el-form>
      <el-row>
        <el-col
          v-for="item in options"
          :key="item.label"
          :span="4"
        >
          <el-form-item class="input" :label="item.label">
            <el-select v-model="item.value" clearable placeholder="请选择">
              <el-option
                v-for="option in item.options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          label: '一级组织',
          value: '',
          options: [
            {
              value: '一级组织1',
              label: '一级组织1',
            },
            {
              value: '一级组织2',
              label: '一级组织2',
            },
            {
              value: '一级组织3',
              label: '一级组织3',
            },
          ]
        },
        {
          label: '二级组织',
          value: '',
          options: [
            {
              value: '二级组织1',
              label: '二级组织1',
            },
            {
              value: '二级组织2',
              label: '二级组织2',
            },
            {
              value: '二级组织3',
              label: '二级组织3',
            },
          ]
        },
        {
          label: '三级组织',
          value: '',
          options: [
            {
              value: '三级组织1',
              label: '三级组织1',
            },
            {
              value: '三级组织2',
              label: '三级组织2',
            },
            {
              value: '三级组织3',
              label: '三级组织3',
            },
          ]
        },
      ]
    }
  }
}
</script>

<style lang="scss">
.report-fliters {
  .el-form-item {
    margin-top: $padding;
    margin-bottom: 0;
  }
  .el-form-item__label {
    font-size: 12px;
  }
  .el-input--suffix .el-input__inner {
    border: 1px solid $color-border-secondary;
    border-radius: $border-radius-s;
  }
}
</style>